<template>
  <b-modal id="bv-modal-options-variants" title="Variantes" hide-footer>
    <div v-if="selectedVariant">
      <h4 class="text-center mb-2">Opciones de variante</h4>
      <div
        v-for="(variant, index) in selectedVariant.variant_options_attributes"
        :key="index"
        class="d-flex align-items-center justify-content-between mt-1"
      >
        <b-badge pill :variant="'light-info'">
          {{ variant.option_name }}
        </b-badge>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    selectedVariant: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
