<template>
  <b-modal
    id="bv-modal-edit-options-variants"
    @hidden="resetNewVariantOption"
    hide-footer
  >
    <div v-if="variant">
      <h4 class="text-center mb-2">Opciones de variante</h4>
      <validation-observer ref="optionVariantsRules" v-slot="{ invalid }">
        <validation-provider
          #default="{ errors }"
          name="variante"
          rules="required"
        >
          <b-input-group size="sm">
            <b-form-input
              placeholder="Agregar nueva variante"
              v-model="newVariantOption"
              :state="errors.length > 0 ? false : null"
              name="variante"
            />
            <b-input-group-append>
              <b-button
                variant="outline-success"
                @click="handleAddOptionvariant()"
                :disabled="invalid"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
      <div
        v-for="(variant, index) in variant.variant_options_attributes"
        :key="index"
        class="d-flex align-items-center justify-content-between mt-1"
      >
        <b-badge pill :variant="'light-info'">
          {{ variant.option_name }}
        </b-badge>
        <b-button
          size="sm"
          variant="danger"
          @click="handleDeleteOptionVariant(variant)"
          class="delete-button"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required } from "@validations";

export default {
  data() {
    return {
      newVariantOption: null,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {
    variant: {
      type: Object,
      default: () => {},
    },

    handleDeleteOptionVariant: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("variants", ["fetchVariants", "addOptionVariant"]),

    handleAddOptionvariant() {
      this.$refs.optionVariantsRules.validate().then((success) => {
        if (success) {
          this.addOptionVariant({
            variant_id: this.variant.id,
            option_name: this.newVariantOption,
          }).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Éxito",
                text: "Opción de variante agregada",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.newVariantOption = null;
            this.fetchVariants({
              meta: {
                pagination: {
                  per_page: this.entriesPerPage,
                },
              },
            }).then((res) => {
              this.variant = this.variants.find(
                (variant) => variant.id === this.variant.id
              );
            });
          });
        }
      });
    },
    resetNewVariantOption() {
      this.newVariantOption = null;
    },
  },
};
</script>
