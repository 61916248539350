<template>
  <b-list-group class="variants-list">
    <b-list-group-item
      v-for="variant in variants"
      :key="variant.id"
      class="d-flex justify-content-between align-items-center"
      @click="handleShowOptionsVariantModal(variant)"
    >
      <p class="mb-0 mr-2 text-break">{{ variant.name }}</p>
      <b-badge
        v-if="variant.active_status === true"
        pill
        variant="light-success"
      >
        Activo
      </b-badge>
      <b-badge v-else pill variant="light-danger"> Cancelado </b-badge>

      <b-dropdown
        variant="link"
        no-caret
        class="ml-auto"
        :right="$store.state.appConfig.isRTL"
        boundary="viewport"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item
          v-if="variant.active_status === true"
          @click.stop="deactiveVariant(variant.id)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Desactivar</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="variant.active_status === false"
          @click.stop="reactiveVariant(variant.id)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Reactivar</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click.stop="handleShowEditOptionsVariantModal(variant)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Editar opciones de variante</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    deactiveVariant: {
      type: Function,
      default: () => {},
    },
    reactiveVariant: {
      type: Function,
      default: () => {},
    },
    handleShowOptionsVariantModal: {
      type: Function,
      default: () => {},
    },
    handleShowEditOptionsVariantModal: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
