<template>
  <b-table
    ref="refVariantListTable"
    hover
    :items="variants"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
    class="position-relative variants-table"
  >
    <!-- Column: Variant name -->
    <template #cell(name)="data">
      <template>
        <div>
          <b-badge pill :variant="'light-success'">
            {{ data.item.name }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Column: Variant options -->
    <template #cell(variant_options)="data">
      <div class="d-flex options-flex">
        <div
          v-for="(option, index) in data.item.variant_options_attributes"
          :key="index"
        >
          <b-badge pill :variant="'light-info'">
            {{ option.option_name }}
          </b-badge>
        </div>
      </div>
    </template>

    <!-- Column: Status-->
    <template #cell(status)="data">
      <b-badge
        v-if="data.item.active_status === true"
        pill
        :variant="'light-success'"
      >
        Activo
      </b-badge>
      <b-badge v-else pill :variant="'light-danger'"> Cancelado </b-badge>
    </template>

    <!-- Dropdown -->
    <template #cell(actions)="data">
      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item
          v-if="data.item.active_status === true"
          @click="deactiveVariant(data.item.id)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Desactivar</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="data.item.active_status === false"
          @click="reactiveVariant(data.item.id)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Reactivar</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleShowOptionsVariantModal(data.item)">
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Editar opciones de variante</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
export default {
  data() {
    return {
      tableColumns: [
        {
          key: "name",
          label: "Variante",
          sortable: true,
        },
        {
          key: "variant_options",
          label: "Opciones de variante",
        },
        {
          key: "status",
          label: "Estatus",
        },
        {
          key: "actions",
          label: "Editar",
        },
      ],
    };
  },
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    deactiveVariant: {
      type: Function,
      default: () => {},
    },
    reactiveVariant: {
      type: Function,
      default: () => {},
    },
    handleShowOptionsVariantModal: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
